import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@ngneat/transloco';
import { CustomizedSnackbarComponent } from 'app/shared/components/customized-snackbar/customized-snackbar.component';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private _snackBar: MatSnackBar,
    ) { }
    successMessage = 'success-message-snackbar';
    errorMessage = 'error-snackbar';
    infoMessage = 'info-message-snackbar';

    showSnackBar({ message, snackBarType, disableTranslate }: { message: string, snackBarType: string, disableTranslate?: boolean }): void {

        this._snackBar.openFromComponent(CustomizedSnackbarComponent, {

            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: [snackBarType], // .success-message-snackbar .error-snackbar .info-message-snackbar
            duration: 5000,
            data: {
                snackBarType,
                msg: disableTranslate ? message : translate(message),
            }
        });
    }

    /** new independant window opener */
    windowOpener({ url }: { url: string }): Window | null {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const windowWidth = 600;
        const windowHeight = 520;
        const left = (screenWidth - windowWidth) / 2;
        const top = (screenHeight - windowHeight) / 2;
        const newWindow = window.open(
            url,
            '_blank',
            `width=${windowWidth},height=${windowHeight},left=${left},top=${top}`
        );
        return newWindow;
    }
}
